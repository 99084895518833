<template>
	<div>
		<div @click="clickMethod({ postId: data.id })" class="mt-3 mr-2 break-words hover:bg-gray-50">
			<div
				class="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5"
			>
				<div class="relative px-3 pb-3 grid gap-1 sm:gap-1">
					<div class="flex justify-between mt-2">
						<slot />
						<span class="content-end block p-1 ml-auto mr-5 text-sm">{{
							formatDate({ date: data.date })
						}}</span>
						<component :is="icon" />
					</div>
					<div
						class="mb-2 rounded-lg cursor-pointer grid-col-3 "
					>
						<div class="flex flex-col items-start mb-2">
							<div>
								<i style="padding-left:1rem; padding-right:3rem;">{{
									data.source_group_name
								}}</i>
							</div>
							<div v-if="data.is_forward" class="ml-4">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="16"
									height="16"
									fill="currentColor"
									viewBox="0 0 16 16"
									class="inline"
								>
									<path
										fill-rule="evenodd"
										d="M14.854 4.854a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 4H3.5A2.5 2.5 0 0 0 1 6.5v8a.5.5 0 0 0 1 0v-8A1.5 1.5 0 0 1 3.5 5h9.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4z"
									/>
								</svg>
								<i>{{ data.forward_channel_name }}</i>
							</div>
						
						</div>
						<div class="flex ml-4">
							<div
								v-html="truncate(xss(data.title), 160)"
								class="text-base font-medium text-gray-900"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import xss from "xss";

export default {
	name: "PostItem",
	props: {
		data: {
			type: Object,
		},
		clickMethod: {
			type: Function,
		},
	},
	methods: {
		xss,
		formatDate({ date }) {
			return (
				new Date(date).toLocaleDateString() +
				" " +
				new Date(date).toLocaleTimeString()
			);
		},
		truncate(str, n) {
			return str.length > n ? str.substr(0, n - 1) + "&hellip;" : str;
		},
	},
	computed: {
		icon() {
			return () => import(`./icons/${this.data.source}.vue`);
		},
	},
};
</script>
