import { axiosInstance } from '@/config/axios'

export const exportDocx = async postIds => {
  try {
    const { data } = await (await axiosInstance()).post(
      `export/posts/docx`,
      {
        post_ids: postIds
      },
      { responseType: 'blob' }
    )
    return data
  } catch (error) {
    console.debug(error)
  }
}
