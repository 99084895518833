<template>
  <div>
    <t-modal v-model="showExport">
      <div class="flex">
        <select
          v-model="exportType"
          id="export-type"
          name="export-type"
          class="inline-flex flex-1 h-12 px-3 py-2 uppercase bg-white border border-r-0 border-gray-300 rounded rounded-r-none shadow-sm focus:outline-none sm:text-sm"
        >
          <option v-for="value in exportTypes" :value="value" :key="value">{{ $i18n(value).toUpperCase() }}</option>
        </select>
        <button
          @click="exportPosts"
          type="button"
          class="inline-flex justify-center h-12 px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-l-0 border-r-0 border-transparent rounded-l-none rounded-l-0 shadow-sm rounded-md hover:bg-blue-700 focus:outline-none"
        >
          <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
          </svg>
        </button>
      </div>
    </t-modal>

    <div class="container mx-auto mt-5 mt-10">
      <div class="flex items-center pl-2">
        <button
          @click="togglePostFilter"
          type="button"
          class="inline-flex justify-center px-2 py-2 mb-2 text-sm font-medium border h-9 border-gray shadow-sm rounded-md hover:bg-grey-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300"
        >
          <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z"
            />
          </svg>
        </button>
        <div class="ml-5 text-sm">
          {{ $i18n('Posts shown') }}: {{ posts.data.length }} {{ $i18n('from') }}
          {{ posts.meta.results_count }}
        </div>
        <div v-if="newPostsCounter" :key="newPostsCounter" class="ml-5" @click="refresh">
          <span class="underline cursor-pointer ">{{ $i18n('New posts') }}:</span>
          <span class="text-green-400">{{ newPostsCounter }}</span>
        </div>
        <svg v-if="postsLoad" class="block w-8 h-8 ml-2 text-white animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
          <path class="opacity-85" fill="#ccc" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
      </div>
      <div class="flex">
        <div v-show="showPostFilter" class="flex-none">
          <PostsFilter :feed-id="id" :keywords="posts.meta.filters.keywords" :sources="sources" :sources-types="posts.meta.filters.source" :results-count="posts.meta.results_count" />
        </div>
        <div @scroll="scrollListListener" class="flex flex-1 pb-10 theme">
          <div ref="postsStart" />
          <div class="sticky z-10 flex-none w-8 mx-2 top-4">
            <div v-if="showScrollUp" @click="scrollUp" class="fixed p-1 bg-gray-200 cursor-pointer bottom-5">
              <Tooltip :text="$i18n('Scroll up')">
                <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 11l5-5m0 0l5 5m-5-5v12" />
                </svg>
              </Tooltip>
            </div>

            <template v-if="(posts.data.length && postsExport.length) || newPostsCounter">
              <Tooltip v-if="postsExport.length">
                <template #text>
                  {{ $i18n('Selected posts') }}:

                  <span class="text-green-400">{{ postsExport.length }}</span>
                </template>
                <div @click="onlySelectedPosts" class="inline-flex flex-col justify-center p-1 text-sm font-medium bg-gray-100 rounded-t cursor-pointer hover:bg-gray-50 focus:outline-none">
                  <div class="text-xs text-center">
                    {{ postsExport.length > 99 ? postsExport.length.toString().concat('+') : postsExport.length }}
                  </div>
                  <div>
                    <svg xmlns="http://www.w3.org/2000/svg" :class="`inline w-6 h-6 ${prevPosts.length ? 'text-green-500' : ''}`" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                      />
                    </svg>
                  </div>
                </div>
              </Tooltip>

              <Tooltip v-if="postsExport.length" :text="$i18n('Un check all selected posts')">
                <button @click="unCheckPosts" type="button" class="inline-flex justify-center p-1 text-sm font-medium bg-gray-100 hover:bg-gray-50 focus:outline-none">
                  <svg xmlns="http://www.w3.org/2000/svg" class="inline w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </Tooltip>
              <Tooltip v-if="postsExport.length" :text="$i18n('Export posts')">
                <button @click="toggleShowExport" type="button" class="inline-flex justify-center p-1 text-sm font-medium bg-gray-100 hover:bg-gray-50 focus:outline-none">
                  <svg xmlns="http://www.w3.org/2000/svg" class="inline w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke="currentColor" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                  </svg>
                </button>
              </Tooltip>
            </template>
          </div>

          <div class="pb-5 last-box">
            <template v-for="(post, index) in posts.data">
              <div :ref="`${post.cdate}`" :key="`lastShownPost${index}`" class="my-5 ml-2 mr-3 bg-blue-500" />
              <PostItem :key="`post${index}`" :data="post" :clickMethod="fetchDetails">
                <div>
                  <input
                    :value="post"
                    v-model="postsExport"
                    @click.stop=""
                    showScrollUp
                    type="checkbox"
                    class="w-4 h-4 m-auto text-blue-600 border-gray-300 rounded cursor-pointer focus:ring-blue-500"
                  />
                </div>
              </PostItem>
            </template>
            <div v-if="posts.data.length && posts.data.length !== posts.meta.results_count && !prevPosts.length" class="mt-3">
              <div
                @click="showMore"
                class="flex items-center justify-center px-8 py-3 mr-2 text-base font-medium text-white bg-blue-600 border border-transparent cursor-pointer rounded-md hover:bg-blue-700 md:py-4 md:text-lg md:px-10"
              >
                {{ $i18n('Show more') }}
              </div>
            </div>
          </div>
        </div>
        <div ref="postDetails" class="flex-1 theme">
          <PostDetails v-if="post" :data="post" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex'
import PostsFilter from '@/components/PostsFilter'
import PostItem from '@/components/PostItem'
import PostDetails from '@/components/PostDetails'
import Tooltip from '@/components/Tooltip'
import Papa from 'papaparse'
import { exportDocx } from '@/utils/api/export'

export default {
  name: 'FeedView',
  props: {
    id: {
      type: String
    }
  },
  data() {
    return {
      post: null,
      offset: 0,
      newPostsCounter: 0,
      intervalId: undefined,
      postsExport: [],
      exportType: 'text',
      exportTypes: ['text', 'docx', 'csv', 'json'],
      showExport: false,
      showPostFilter: true,
      prevPosts: [],
      lastShown: '',
      postsFetching: async () => {},
      showScrollUp: false
    }
  },
  components: {
    PostsFilter,
    PostItem,
    PostDetails,
    Tooltip
  },
  computed: {
    ...mapGetters('posts', ['postsLimit', 'postsLoad', 'posts', 'sources']),
    lastDate() {
      const {
        posts: { data }
      } = this

      const cdates = data.map(({ cdate }) => Date.parse(cdate))
      const maxDate = Math.max(...cdates)

      return new Date(maxDate).toISOString()
    }
  },
  methods: {
    ...mapActions('posts', ['fetchPosts', 'fetchPostById', 'exportDocx']),
    ...mapActions('feeds', ['fetchFeedById', 'updateFeedById']),
    ...mapMutations('posts', ['setPosts', 'setPostsData']),
    async fetch({ after, withOffset = false, withSetData = true } = {}) {
      try {
        const {
          $route: { query }
        } = this

        const { data } = await this.fetchPosts({
          feedId: this.id,
          query: { ...query, after, offset: withOffset ? this.offset : undefined },
          withSetData,
          withOffset
        })

        this.postsFetching = async () => true

        if (!withSetData) {
          this.newPostsCounter = data.length
        }
        return
      } catch (error) {
        console.debug(error)
      }
    },
    async fetchDetails({ postId = String(this.$route.hash).substr(1) } = {}) {
      try {
        await this.$nextTick()
        this.$refs.postDetails.scrollTop = 0

        if (postId) {
          await this.syncCurrentPost({
            currentPost: postId
          })

          const data = await this.fetchPostById(postId)
          this.post = data
        }
      } catch (error) {
        console.debug(error)
      }
    },
    async syncCurrentPost({ currentPost }) {
      try {
        const { $router, $route } = this
        await $router.replace({
          ...$route,
          hash: '#' + currentPost
        })
      } catch (error) {
        console.debug(error)
      }
    },
    async showMore() {
      try {
        this.offset = this.offset + this.postsLimit
        const { fetch } = this
        await fetch({ withOffset: true })
        await this.$nextTick()
        await this.renderLast()
      } catch (error) {
        console.debug(error)
      }
    },
    spyNewPosts() {
      try {
        const { fetch, lastDate } = this
        this.intervalId = setInterval(() => {
          try {
            fetch({ after: lastDate, withSetData: false })
          } catch (error) {
            console.debug(error)
          }
        }, 30000)
      } catch (error) {
        console.debug(error)
      }
    },
    async refresh() {
      try {
        this.newPostsCounter = 0
        await this.fetch()

        await this.$nextTick()
        await this.renderLast()
        this.cleanupInterval()
        this.spyNewPosts()
      } catch (error) {
        console.debug(error)
      }
    },
    async exportPosts() {
      try {
        const types = {
          json: 'application/json',
          csv: 'text/csv',
          text: 'text/plain',
          docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        }
        let file
        switch (this.exportType) {
          case 'csv':
            file = Papa.unparse(this.postsExport)
            break
          case 'json':
            file = JSON.stringify(this.postsExport)
            break
          case 'text':
            file = this.postsExport
              .map(
                ({ source, source_group_name, date, title, content, external_url }) =>
                  `${source_group_name}\n${date}${source === 'telegram' || source === 'twitter' ? '' : '\n\n' + title}\n\n${content}\n\n${external_url || ''}`
              )
              .join(`\n\n———————————\n\n`)
            break
          case 'docx':
            file = await exportDocx(this.postsExport.map(({ id }) => id))
            break
        }

        let element = document.createElement('a')
        if (this.exportType === 'docx') {
          element.setAttribute('href', URL.createObjectURL(file))
        } else {
          element.setAttribute('href', 'data:' + types[this.exportType] + ';charset=utf-8,' + encodeURIComponent(file))
        }
        const exportType = this.exportType === 'text' ? 'txt' : this.exportType
        const { name } = await this.fetchFeedById(this.id)
        element.setAttribute('download', name + '.' + exportType)
        element.click()
      } catch (error) {
        console.debug(error)
      }
    },
    togglePostFilter() {
      try {
        this.showPostFilter = !this.showPostFilter
      } catch (error) {
        console.debug(error)
      }
    },
    toggleShowExport() {
      try {
        this.showExport = !this.showExport
      } catch (error) {
        console.debug(error)
      }
    },
    unCheckPosts() {
      try {
        this.postsExport = []
      } catch (error) {
        console.debug(error)
      }
    },
    onlySelectedPosts() {
      try {
        if (!this.prevPosts.length) {
          this.prevPosts = this.posts.data
          this.setPostsData(this.postsExport)
        } else {
          this.setPostsData(this.prevPosts)
          this.prevPosts = []
        }
      } catch (error) {
        console.debug(error)
      }
    },
    async renderLast() {
      try {
        await this.postsFetching()

        const { last_shown } = await this.fetchFeedById(this.id)

        const last = this.posts.data.filter(post => {
          try {
            return Date.parse(post.cdate) < Date.parse(last_shown)
          } catch (error) {
            console.debug(error)
          }
        })

        if (last.length) {
          await this.$nextTick()

          try {
            this.posts.data.forEach(post => {
              try {
                this.$refs[post.cdate][0].style.height = 0
              } catch (error) {
                console.debug(error)
              }
            })
            this.$refs[last[0].cdate][0].style.height = '1px'
          } catch (error) {
            console.debug(error)
          }
        }
        return
      } catch (error) {
        console.debug(error)
      }
    },
    scrollUp() {
      try {
        this.$refs.postsStart.scrollIntoView()
      } catch (error) {
        console.log(error)
      }
    },
    scrollListListener(event) {
      try {
        const {
          target: { scrollTop }
        } = event
        if (scrollTop >= 100) {
          this.showScrollUp = true
        } else {
          this.showScrollUp = false
        }
      } catch (error) {
        console.debug(error)
      }
    },
    cleanupInterval() {
      try {
        clearInterval(this.intervalId)
      } catch (error) {
        console.debug(error)
      }
    }
  },
  async created() {
    try {
      const { fetch, fetchDetails, spyNewPosts, renderLast, id } = this
      this.$on('Filter', async () => {
        await fetch()
        await this.$nextTick()
        await this.renderLast()
      })
      await fetch()
      fetchDetails()
      spyNewPosts()
      await renderLast()
      this.updateFeedById({
        feedId: id,
        payload: {
          last_shown: new Date().toISOString()
        }
      })
    } catch (error) {
      console.debug(error)
    }
  },

  beforeDestroy() {
    this.cleanupInterval()
  }
}
</script>
<style>
.theme {
  overflow-y: auto;
  height: 100%;
  min-height: calc(100vh - 200px);
  max-height: calc(100vh - 200px);
}
</style>
