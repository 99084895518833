<template>
	<div class="relative tooltip">
		<div
			class="absolute z-50 block p-2 text-xs text-white bg-black rounded-full rounded-bl-none shadow-lg min-w-max left-full invisible tooltip-text"
		>
			<div v-html="text" />
			<slot name="text" />
		</div>
		<slot />
	</div>
</template>

<script>
export default {
	name: "Tooltip",
	props: {
		text: {
			type: String,
			default: "",
		},
	},
};
</script>
<style scoped>
.tooltip:hover .tooltip-text {
	visibility: visible;
}
</style>
